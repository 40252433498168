export class User{
    userid:number;
    name:string;
    mobile:string;
    email:string;
    address:string;
    password:string;
    cpassword:string;
    lat:number
    lng:number
    areaid:number
    role:string;
    fcmtoken:string
    isBlocked:number
    minbill:number
    delcharge:number
    dcupto:number
    type:string
    task:string
    otp:number
}


